











































import { computed, defineComponent, nextTick, onMounted, ref } from '@vue/composition-api'
import { useEventListener } from '@vueuse/core'
import GCarousel from '@/components/GCarousel.vue'
// @ts-ignore
import Slide from 'vue-carousel/src/Slide.vue'
import useBreakpoint from '@/composables/use-breakpoint'
import useHomeCarousel from '@/composables/home-carousel/use-home-carousel'

enum ModalType {
  GO_POINT = 'GO_POINT'
}

export default defineComponent({
  components: {
    GCarousel,
    Slide,
  },
  setup () {
    const { list } = useHomeCarousel()

    const { match } = useBreakpoint()
    const isMobile = !match('lg')

    const carousel = ref<Vue | null>(null)

    const clickPrevious = (e: Event) => {
      e.stopPropagation()

      /**
       * 在點擊 navigation 後會暫停 autoplay
       * see node_modules/vue-carousel/src/Carousel.vue:602
       * handleNavigation
       *
       * 因此強迫在 navigation 按下時 繼續 autoplay
       * 超暴力超醜 ==
       */
      try {
       (carousel.value?.$children[0] as any)?.pauseAutoplay.call(carousel.value?.$children[0])
       (carousel.value?.$children[0] as any)?.restartAutoplay.call(carousel.value?.$children[0])
      } catch {}
    }
    const clickNext = (e: Event) => {
      e.stopPropagation()

      /**
       * 在點擊 navigation 後會暫停 autoplay
       * see node_modules/vue-carousel/src/Carousel.vue:602
       * handleNavigation
       *
       * 因此強迫在 navigation 按下時 繼續 autoplay
       * 超暴力超醜 ==
       */
      try {
       (carousel.value?.$children[0] as any)?.pauseAutoplay.call(carousel.value?.$children[0])
       (carousel.value?.$children[0] as any)?.restartAutoplay.call(carousel.value?.$children[0])
      } catch {}
    }

    onMounted(async () => {
      await nextTick()
      const el = carousel.value?.$el
      const previous = el?.querySelector('.VueCarousel-navigation-prev')
      const next = el?.querySelector('.VueCarousel-navigation-next')

      useEventListener(previous, 'click', clickPrevious)
      useEventListener(next, 'click', clickNext)
    })

    const imgPerPage = isMobile ? 1 : 3

    const popupModal = ref<ModalType | null>(null)

    const closeModal = () => {
      popupModal.value = null
    }

    const carouselBind = computed(() => ({
      autoplay: true,
      autoplayTimeout: 5000,
      loop: true,
    }))

    return {
      carousel,
      imgPerPage,
      list,

      ModalType,
      popupModal,
      closeModal,

      carouselBind,
    }
  },
})
