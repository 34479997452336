



















import { defineComponent, onMounted } from '@nuxtjs/composition-api'
import Cookies from 'js-cookie'

import useShowable from '@/composables/use-showable'
import { updateConsent } from '@/modules/ga4'
import { clarityConsent } from '@/modules/clarity'

export default defineComponent({
  setup () {
    // 為了相容舊站 Cookie Info 而取此名
    // V2: 同意聲明模式需要重新把同意橫幅召回
    const key = 'CookieInfoScript_V3'
    const { isShow, show, hide } = useShowable()

    onMounted(() => {
      if (!Cookies.get(key)) show()
      else {
        const granted = Cookies.get(key) === 'true'
        updateConsent(granted ? 'granted' : 'denied')
        if (granted) handleClarityConsent()
      }
    })

    const close = (granted: boolean) => {
      hide()
      Cookies.set(key, String(granted), { expires: 365 })

      updateConsent(granted ? 'granted' : 'denied')
      if (granted) handleClarityConsent()
    }

    /**
     * 更新 Clarity 同意聲明，
     * Clarity 被掛在 GTM 上，
     * 需等待 Clarity 載入完成
     */
    const handleClarityConsent = () => {
      const interval = setInterval(() => {
        if (window.clarity) {
          clarityConsent()
          clearInterval(interval)
        }
      }, 1000)
    }

    return {
      isShow,
      close,
      handleClarityConsent,
    }
  },
})
