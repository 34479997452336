





import { computed, defineComponent, PropType } from '@vue/composition-api'
import useFeatureFlag from '@/composables/use-feature-flag'

export default defineComponent({
  props: {
    featureKey: {
      type: String,
      required: true,
    },
    /**
     * 可以直接 assign Boolean, String, Number, Object 數值，會直接用 lodash 的 equal 做比較
     * 也可以 assign 矩陣，適用於兩種以上的 feature value，其中一個值有比對中就會呈現元件內容
     */
    featureValue: {
      type: [Boolean, String, Number, Object, Array] as PropType<Boolean | String | Number | Object | Array<any>>,
      required: true,
    },
  },
  setup (props) {
    const { match } = useFeatureFlag()

    const show = computed(() => {
      return match(props.featureKey, props.featureValue)
    })

    return {
      show,
    }
  },
})
