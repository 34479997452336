import { FeatureDefinition } from '@growthbook/growthbook'

export enum FeatureFlagKey {
  VISIBLE_LOCALES = 'nuxt-visible-locales',
  PAYMENT_SERVICE_PROVIDER = 'payment-service-provider',
  PREVIOUS_SEARCH_ON_TOP = 'previous_search_on_top',
  BETA_RANKER = 'beta-ranker',
  SHOW_AI_GEN_CAR_IMAGE = 'nuxt-show-ai-gen-car-image',
  INDEX_BANNER = 'nuxt_index_banner'
}

const FeatureFlags: Record<string, FeatureDefinition> = {
  /**
   * 開放的語系
   */
  [FeatureFlagKey.VISIBLE_LOCALES]: {
    defaultValue: 'en',
  },
  /**
   * 使用哪家金流商
   * TAPPAY: 1
   * PAYMENTS91APP: 2
   * NEWEBPAY: 3
   */
  [FeatureFlagKey.PAYMENT_SERVICE_PROVIDER]: {
    defaultValue: 3,
  },
  /**
   * search是否將之前搜尋顯示在前面
   * false: 不顯示
   * true: 顯示
   */
  [FeatureFlagKey.PREVIOUS_SEARCH_ON_TOP]: {
    defaultValue: false,
  },
  /**
   * beta-ranker
   * false: 不經過
   * true: 經過
   */
  [FeatureFlagKey.BETA_RANKER]: {
    defaultValue: false,
  },
  /**
   * 顯示 AI Gen 車圖
   * false: 不顯示
   * true: 顯示
   */
  [FeatureFlagKey.SHOW_AI_GEN_CAR_IMAGE]: {
    defaultValue: false,
  },
  /**
   * 首頁 banner 顯示
   * 1: 顯示舊圖
   * 2: 顯示新圖
   * 3: 移除
   */
  [FeatureFlagKey.INDEX_BANNER]: {
    defaultValue: 0,
  },
}

export type FlagKey = string

export default FeatureFlags
