import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'

import { CancelOrderResponse, ChangeInvoiceResponse, CheckReviewResponse, DeleteSessionResponse, EmailExistResponse, GetCancelOrderResponse, CreateGogooutOrderResponse, CreateHertzOrderResponse, GetHertzQuoteResponse, GetLocationPickerShortcutResponse, GetOrderDetailResponse, GetOrdersResponse, GetPaymentInfoResponse, GetQuoteResponse, GetReferenceResponse, GetReviewListResponse, GetStoreReviewsResponse, GetCitySuggestionInfoResponse, GetCountrySuggestionInfoResponse, GetUserProfileResponse, LoginResponse, RegisterResponse, ResendMailResponse, ResetPasswordResponse, SendResetPasswordEmailResponse, SsoLoginResponse, UpdateUserInfoResponse, SearchCarsResponse, SearchFuzzyCarsResponse, SearchHertzCarsResponse, GetCarInfoResponse, GetHertzCarInfoResponse, GetSpecialCarInfoResponse, GetHertzStoreResponse, ApplyCorporateMemberResponse, GetStoreCommentsResponse, GetStoreResponse, GetStorCarsResponse, GetDistrictResponse, RefreshTokenResponse, GetCardsResponse, GetCommercialInvoiceResponse } from '@/types/ggo-api/responses'
import { CancelOrderDto, ChangeInvoiceDto, CheckReviewDto, EmailExistDto, GetCancelOrderDto, CreateGogooutOrderDto, CreateHertzOrderDto, GetHertzQuoteDto, GetOrderDetailDto, GetPaymentInfoDto, GetQuoteDto, GetStoreReviewsDto, GetCitySuggestionInfoDto, GetCountrySuggestionInfoDto, PostReviewDto, LoginDto, RegisterDto, ResendMailDto, ResetPasswordDto, SendResetPasswordEmailDto, SsoLoginDto, UpdateUserInfoDto, ApplyCandidateDto, SearchCarsDto, SearchFuzzyCarsDto, SearchHertzCarsDto, GetCarInfoDto, GetSpecialCarInfoDto, GetHertzStoreDto, GetHertzCarInfoDto, ApplyCorporateMemberDto, GetStoreCommentsDto, GetStoreDto, GetDistrictDto, GetStoreCarsDto, GetReferenceDto, InvalidateCardDto, GetCommercialInvoiceDto } from '@/types/ggo-api/dto'
import GgoResponse from '@/helpers/ggo-response'
import ggoLogger from '@/modules/ggo-logger'
import { ErrorType, LogName, Severity } from '@/interfaces/Logger'
import * as qs from 'qs'
import Cookies from 'js-cookie'
import GgoTrackKey from '@/enums/GgoTrackKey'
import { GetServiceLocationsResponse } from '~/types/ggo-api/responses/ServiceLocation'

/** 監控白名單, 錯誤時不上報到日誌系統 */
const monitorWhiteListSet = new Set([
  /** 用戶 email 是否已存在 */
  '/api/member/exists',
])
/** refreshToken 白名單, 避免請求時觸發 refresh 機制 */
// const tokenInterceptorWhiteListSet = new Set([
//   /** 用戶登入/登出 */
//   '/api/session',
//   /** refreshToken */
//   '/api/session/refresh',
// ])

const HEADER_REQUEST_START_TIME = 'X-Request-Start-Time'
const HEADER_GGO_SESSION_ID = 'X-Ggo-Session-Id'
const HEADER_GGO_DEVICE_ID = 'X-Ggo-Device-Id'

export class GgoApi {
  axiosInstance: AxiosInstance
  token: string = ''
  locale: string = 'en' // fallback en
  prefix = '/api'

  // 確保有個預設 Error message
  static DefaultErrorMsg = 'Unhandled error happens'

  constructor () {
    if (typeof window !== 'undefined') {
      this.token = localStorage.getItem('token') ?? ''
    }

    this.axiosInstance = axios.create({
      baseURL: `${process.env.NUXT_BASE_URL}`,
      timeout: 30000,
    })

    // this.createTokenInterceptor()
    this.createResponseTimeInterceptor()
  }

  // axios 攔截器
  request<T> (params: AxiosRequestConfig, defaultValue: T | null = null): Promise<GgoResponse<T>> {
    if (typeof window !== 'undefined') {
      this.token = localStorage.getItem('token') ?? ''
    }

    return this.axiosInstance.request({
      ...params,
      headers: {
        common: {
          'Accept-Language': this.locale,
        },
        ...this.token && {
          Authorization: `Bearer ${this.token}`,
        },
        [HEADER_REQUEST_START_TIME]: Date.now(),
        ...(!process.server && {
          ...(Cookies.get(GgoTrackKey.SESSION_ID_STORE_KEY) && {
            [HEADER_GGO_SESSION_ID]: Cookies.get(GgoTrackKey.SESSION_ID_STORE_KEY),
          }),
          ...(Cookies.get(GgoTrackKey.DEVICE_ID_STORE_KEY) && {
            [HEADER_GGO_DEVICE_ID]: Cookies.get(GgoTrackKey.DEVICE_ID_STORE_KEY),
          }),
        }),
      },
      // 防止 redirect: context: dev防止被打爆, 所以 return 302 導彩蛋
      maxRedirects: 1,
    })
      .then((res) => {
        const data = res?.data ?? defaultValue
        const status = res?.status
        return new GgoResponse<T>({ data, status, error: null, success: true })
      })
      .catch((e: AxiosError) => {
        // 上報 API 錯誤日誌
        this.reportErrorLog(e, { errorType: ErrorType.API_ERROR })

        const status = e.response?.status || 0
        return new GgoResponse<T>({ data: null, status, error: e.response?.data.message || e.message || GgoApi.DefaultErrorMsg, success: false })
      })
  }

  /** TBD */
  /** response 攔截器 - refresh token */
  // createTokenInterceptor () {
  //   let isRefreshing = false
  //   let requestQueue: ((newToken: string) => void)[] = []
  //   const cleanUp = () => {
  //     requestQueue = []
  //     isRefreshing = false
  //   }

  //   this.axiosInstance.interceptors.response.use(
  //     response => response,
  //     async (error: AxiosError) => {
  //       // early return
  //       if (error.response?.status !== 401 || tokenInterceptorWhiteListSet.has(error.config.url || '')) {
  //         return Promise.reject(error)
  //       }

  //       /**
  //        * 如果正在發送請求中, 後續請求 enqueue, 避免發送多個請求
  //        * source: https://zhuanlan.zhihu.com/p/80125501
  //        */
  //       if (isRefreshing) {
  //         return new Promise((resolve) => {
  //           requestQueue.push((newToken: string) => {
  //             const config = error.response!.config
  //             config!.headers!.Authorization = `Bearer ${newToken}`
  //             resolve(this.axiosInstance(config))
  //           })
  //         })
  //       }

  //       /** refresh and set new token */
  //       isRefreshing = true
  //       const refreshRes = await this.refreshToken()
  //       if (!refreshRes.success || !refreshRes.data) {
  //         cleanUp()
  //         return Promise.reject(error)
  //       }

  //       const token = refreshRes.data.token
  //       localStorage.setItem('token', token)
  //       const config = error.response!.config
  //       config!.headers!.Authorization = `Bearer ${token}`
  //       // 執行 queue 的請求
  //       requestQueue.forEach(reqObj => reqObj(token))
  //       cleanUp()
  //       return this.axiosInstance(config)
  //     }
  //   )
  // }

  /** response 攔截器 - 統計 API 耗時 */
  createResponseTimeInterceptor () {
    this.axiosInstance.interceptors.response.use(
      (response) => {
        const { config } = response
        const end = Date.now()
        const start = config.headers![HEADER_REQUEST_START_TIME] as number

        try {
          ggoLogger.info(LogName.API_REQUEST, {
            http_request: GgoApi.formatHttpRequestLog(config),
            request_start_time: start,
            request_end_time: end,
            request_duration: end - start,
          })
        } catch (e) {
          console.log(e)
        }
        return response
      }
    )
  }

  /** 格式化 Logger 所需的 `http_request` */
  static formatHttpRequestLog (config: AxiosRequestConfig) {
    return {
      // http 在 url 則使用 url, 否則拼接 baseURL
      url: (config.url || '').startsWith('http') ? config.url : `${config.baseURL}${config.url}`,
      method: config.method || '',
      headers: config.headers || {},
      ...(config.params && { params: config.params }),
      ...(config.data && { data: GgoApi.maskSensitiveData(config.data) }),
    }
  }

  // 掩蓋 data 中的密碼
  static maskSensitiveData (data: string, pwdName: string = 'password') {
    const parsedData = JSON.parse(data)
    const formattedData = pwdName in parsedData ? { ...parsedData, [pwdName]: '*****' } : parsedData
    return formattedData
  }

  // 掩蓋 data 中的卡號/cvv, 返回 json 格式 (方便查詢)
  static maskCardNumberInData (data: string) {
    const parsedData = qs.parse(data)
    const parsedCardInfo = JSON.parse(parsedData.jsonString as string)
    const formattedCardInfo = {
      ...parsedCardInfo,
      cardnumber: parsedCardInfo.cardnumber ? '*'.repeat((parsedCardInfo.cardnumber.length - 4)) + parsedCardInfo.cardnumber.slice(-4) : '****',
      cardcvv: parsedCardInfo.cardcvv ? '*'.repeat(parsedCardInfo.cardcvv.length) : '***',
    }
    return { jsonString: formattedCardInfo }
  }

  reportErrorLog (e: AxiosError, { errorType }: { errorType: ErrorType }) {
    // 上報 API 錯誤日誌
    // Why not interceptor? --> 由於 Token 攔截器會 eject 再加入, 為了避免攔截器亂序, 因此不用攔截器
    if (e.config?.url && !monitorWhiteListSet.has(e.config.url)) {
      ggoLogger.error({
        severity: Severity.Warning,
        error_type: errorType,
        http_request: GgoApi.formatHttpRequestLog(e.config),
        status_code: e.response?.status || 0,
        message: e.message,
      })
    }
  }

  setLocale (locale: string) {
    this.locale = locale
  }

  /** 使用者帳號 */
  /** 驗證 email 是否已存在 */
  async emailExist (params: EmailExistDto) {
    return await this.request<EmailExistResponse>({
      url: '/api/member/exists',
      method: 'GET',
      params,
    })
  }

  /** 取得用戶資料 */
  async getUserProfile () {
    return await this.request<GetUserProfileResponse>({
      url: '/api/member/me',
      method: 'GET',
    })
  }

  /** 更新會員資料 */
  async updateUserInfo (params: UpdateUserInfoDto) {
    return await this.request<UpdateUserInfoResponse>({
      url: '/api/member/me',
      method: 'PUT',
      data: { ...params },
    })
  }

  /** 註冊 */
  async register (params: RegisterDto) {
    const referenceCode = Cookies.get(GgoTrackKey.REFERENCE_CODE)
    return await this.request<RegisterResponse>({
      url: '/api/member',
      method: 'POST',
      data: { ...params, referenceCode },
    })
  }

  /** 登入 */
  async login (params: LoginDto) {
    return await this.request<LoginResponse>({
      url: '/api/session',
      method: 'POST',
      data: { ...params },
    })
  }

  /** refresh Token */
  async refreshToken () {
    const token = localStorage.getItem('token') ?? ''
    if (!token) {
      return Promise.resolve<GgoResponse<RefreshTokenResponse>>({ data: { token: '' }, error: '', status: 403, success: false })
    }
    return await this.request<RefreshTokenResponse>({
      url: '/api/session/refresh',
      method: 'POST',
      data: { token },
    })
  }

  /** 第三方登入 */
  async ssoLogin (params: SsoLoginDto) {
    return await this.request<SsoLoginResponse>({
      url: '/api/member/oauth/me',
      method: 'PUT',
      data: { ...params },
    })
  }

  /** 註冊時取得優惠券文案 */
  async getReference (params: GetReferenceDto) {
    return await this.request<GetReferenceResponse>({
      url: '/api/reference',
      method: 'GET',
      params,
    })
  }

  /** 發送重設密碼的 email */
  async sendResetPasswordEmail (params: SendResetPasswordEmailDto) {
    return await this.request<SendResetPasswordEmailResponse>({
      url: '/api/member/password/reset',
      method: 'POST',
      data: { ...params },
    })
  }

  /** 重設密碼 */
  async resetPassword (params: ResetPasswordDto) {
    return await this.request<ResetPasswordResponse>({
      url: '/api/member/password/set',
      method: 'POST',
      data: { ...params },
    })
  }

  /** 移除 session */
  async deleteSession () {
    return await this.request<DeleteSessionResponse>({
      url: '/api/session',
      method: 'DELETE',
    })
  }

  /// /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /** 付款相關 */
  /** 創建訂單（gogoout） */
  async createGogooutOrder (params: CreateGogooutOrderDto) {
    return await this.request<CreateGogooutOrderResponse>({
      url: `/api/v2/order/${params.id}`,
      method: 'POST',
      data: {
        ...params.body,
        id: params.id,
      },
    })
  }

  /** 創建訂單（gogoout） */
  async createHertzOrder (params: CreateHertzOrderDto) {
    return await this.request<CreateHertzOrderResponse>({
      url: '/api/hertz/orders',
      method: 'POST',
      data: { ...params },
    })
  }

  /** 拿訂單編號取得付款連結 */
  async getPaymentInfo (params: GetPaymentInfoDto) {
    return await this.request<GetPaymentInfoResponse>({
      url: `/api/payment/${params.orderNum}`,
      method: 'GET',
    })
  }

  /** 拿已綁定的 card 列表 */
  async getCards () {
    return await this.request<GetCardsResponse>({
      url: '/api/member/cards',
      method: 'GET',
    })
  }

  /** Invalidate card */
  async invalidateCard (params: InvalidateCardDto) {
    return await this.request({
      url: `/api/member/cards/${params.cardId}`,
      method: 'DELETE',
    })
  }

  /** 訂單相關 */
  /** 取得所有訂單 */
  async getOrders () {
    return await this.request<GetOrdersResponse>({
      url: '/api/member/orders',
      method: 'GET',
    })
  }

  /** 取得一張訂單的詳細資訊 */
  async getOrderDetail (params: GetOrderDetailDto) {
    return await this.request<GetOrderDetailResponse>({
      url: `/api/member/orders/${params.orderNum}`,
      method: 'GET',
      params: { vendor: params.vendor },
    })
  }

  /** 取得一張訂單的退款比例 */
  async getCancelOrder (params: GetCancelOrderDto) {
    return await this.request<GetCancelOrderResponse>({
      url: `/api/member/orders/${params.orderNum}/cancel`,
      method: 'GET',
      params: { vendor: params.vendor },
    })
  }

  /** 確定取消一張訂單 */
  async cancelOrder (params: CancelOrderDto) {
    return await this.request<CancelOrderResponse>({
      url: `/api/member/orders/${params.orderNum}/cancel`,
      method: 'POST',
      data: { vendor: params.vendor },
    })
  }

  /** 補記訂單通知信 */
  async resendMail (params: ResendMailDto) {
    return await this.request<ResendMailResponse>({
      url: `/api/member/orders/${params.orderNum}/sendmail`,
      method: 'POST',
      data: { vendor: params.vendor },
    })
  }

  /** 查看該訂單的評論 */
  async checkReview (params: CheckReviewDto) {
    return await this.request<CheckReviewResponse>({
      url: `/api/member/rating/${params.orderNum}`,
      method: 'GET',
    })
  }

  /**
   * 更改訂單發票資訊
   * @deprecated: 在下訂立即開立發票後，即無法修改發票，UI 已拔除，功能保留，未來不需使用再拔除
   */
  async changeInvoice (params: ChangeInvoiceDto) {
    return await this.request<ChangeInvoiceResponse>({
      url: `/api/member/orders/${params.orderNum}/invoice`,
      method: 'PUT',
      data: params.body,
      params: params.params,
    })
  }

  async getCommercialInvoice (params: GetCommercialInvoiceDto) {
    return await this.request<GetCommercialInvoiceResponse>({
      url: `/api/member/orders/${params.orderNum}/invoice`,
      method: 'GET',
      params,
    })
  }

  /** 查看已經評論的訂單列表 */
  async getReviewList () {
    return await this.request<GetReviewListResponse>({
      url: '/api/member/rating',
      method: 'GET',
    })
  }

  /** 前往評論 */
  async postReview (params: PostReviewDto) {
    const { orderNumber, ...data } = params

    return await this.request({
      url: `/api/member/rating/${orderNumber}/rate`,
      method: 'POST',
      data,
    })
  }

  /** 前往評論（免登入） */
  async postReviewWithoutLogin (params: PostReviewDto) {
    const { orderNumber, memberId, ...data } = params

    return await this.request({
      url: `api/member/rating/${orderNumber}/rateWithoutLogin/${memberId}`,
      method: 'POST',
      data,
    })
  }

  /// /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /** 店家 */
  /** 取得一個店家 */
  async getStore (params: GetStoreDto) {
    return await this.request<GetStoreResponse>({
      url: `/api/stores/${params.id}`,
      method: 'GET',
      params: params.params,
    })
  }

  async getStoreCars (params: GetStoreCarsDto) {
    return await this.request<GetStorCarsResponse>({
      url: `/api/stores/${params.id}/cars`,
      method: 'GET',
      params: params.params,
    })
  }

  /** 取得一個店家評論 */
  async getStoreComments (params: GetStoreCommentsDto) {
    return await this.request<GetStoreCommentsResponse>({
      url: `/api/stores/${params.storeId}/comments?page=${params.page}`,
      method: 'GET',
    })
  }

  /** 取得一個店家評價 */
  async getStoreReviews (params: GetStoreReviewsDto) {
    return await this.request<GetStoreReviewsResponse>({
      url: `/api/nuxt/reviews/${params.id}`,
      method: 'GET',
    }, [])
  }

  /** 取得 Hertz 店家資訊 */
  async getHertzStore (params: GetHertzStoreDto) {
    return await this.request<GetHertzStoreResponse>({
      url: `/api/hertz/store/${params.vendor}/${params.locationCode}`,
      method: 'GET',
    })
  }

  /// /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /** 找車（gogoout） */
  /** 找多台車 */
  async searchCars (params: SearchCarsDto) {
    return await this.request<SearchCarsResponse>({
      url: '/api/nuxt/search',
      method: 'GET',
      params,
    })
  }

  /** 找多台車（模糊找車） */
  async searchFuzzyCars (params: SearchFuzzyCarsDto) {
    return await this.request<SearchFuzzyCarsResponse>({
      url: '/api/nuxt/search/fuzzy',
      method: 'GET',
      params,
    })
  }

  /** 找一台車 */
  async getCarInfo (params: GetCarInfoDto) {
    return await this.request<GetCarInfoResponse>({
      url: `/api/nuxt/confirm/${params.id}`,
      method: 'GET',
    })
  }

  /** 特殊找車 */
  async getSpecialCarInfo (params: GetSpecialCarInfoDto) {
    return await this.request<GetSpecialCarInfoResponse>({
      url: `/api/nuxt/confirm/${params.id}`,
      method: 'GET',
      params: {
        token: params.token,
      },
    })
  }

  /** 找一台車的報價 */
  async getQuote (params: GetQuoteDto) {
    const referenceCode = Cookies.get(GgoTrackKey.REFERENCE_CODE)
    return await this.request<GetQuoteResponse>({
      url: `/api/v2/quote/${params.id}`,
      method: 'POST',
      data: { ...params.body, referenceCode },
    })
  }

  /// /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /** 找車（垃圾 Hertz） */
  /** 找多台車 */
  async searchHertzCars (params: SearchHertzCarsDto) {
    return await this.request<SearchHertzCarsResponse>({
      url: '/api/hertz/cars',
      method: 'POST',
      params,
    })
  }

  /** 找一台車 */
  async getHertzCarInfo (params: GetHertzCarInfoDto) {
    return await this.request<GetHertzCarInfoResponse>({
      url: `/api/hertz/cars/${params.vendor}`,
      method: 'POST',
      data: { ...params.body },
    })
  }

  /** 找一台車的報價 */
  async getHertzQuote (params: GetHertzQuoteDto) {
    return await this.request<GetHertzQuoteResponse>({
      url: `/api/hertz/cars/${params.vendor}`,
      method: 'POST',
      data: { ...params.body },
    })
  }

  /// /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /** 難以分辨的雜魚系列 */
  /** 取得 city suggestion 頁資訊 */
  async getCitySuggestionInfo (params: GetCitySuggestionInfoDto) {
    return await this.request<GetCitySuggestionInfoResponse>({
      url: `/api/suggestionCities/${params.city}`,
      method: 'GET',
    })
  }

  /** 取得 country suggestion 頁資訊 */
  async getCountrySuggestionInfo (params: GetCountrySuggestionInfoDto) {
    return await this.request<GetCountrySuggestionInfoResponse>({
      url: `/api/suggestionCountries/${params.country}`,
      method: 'GET',
    })
  }

  async getServiceLocations () {
    return await this.request<GetServiceLocationsResponse>({
      url: '/api/suggestions',
      method: 'GET',
    })
  }

  /** 申請成為 gogoout 商家 */
  async applyCandidate (params: ApplyCandidateDto) {
    const { recaptchaToken, ...data } = params
    return await this.request({
      url: '/api/storeVendor/candidates',
      method: 'POST',
      data: { ...data, recaptcha_token: recaptchaToken },
    })
  }

  /** 地區選擇器 */
  async getLocationPickerShortcut () {
    return await this.request<GetLocationPickerShortcutResponse>({
      url: '/api/nuxt/locationPickerShortcut',
      method: 'GET',
    })
  }

  /** 招募 */
  async applyCorporateMember (params: ApplyCorporateMemberDto) {
    return await this.request<ApplyCorporateMemberResponse>({
      url: '/api/nuxt/corporate/member',
      method: 'POST',
      data: { ...params },
    })
  }

  /** 獲得地區基本資訊 */
  async getDistrict (params: GetDistrictDto) {
    return await this.request<GetDistrictResponse>({
      url: `/api/districts/${params.brief}`,
      method: 'GET',
    })
  }

  async getIp () {
    return await this.request<string>({
      url: '/ip',
      method: 'GET',
    })
  }
}

const ggoApi = new GgoApi()

export default ggoApi
