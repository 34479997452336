import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_54604f30 from 'nuxt_plugin_plugin_54604f30' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_plugin_52fc5932 from 'nuxt_plugin_plugin_52fc5932' // Source: ./composition-api/plugin.js (mode: 'all')
import nuxt_plugin_recaptcha_01dc8496 from 'nuxt_plugin_recaptcha_01dc8496' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_674415ee from 'nuxt_plugin_cookieuniversalnuxt_674415ee' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_vuescrollto_40fb4903 from 'nuxt_plugin_vuescrollto_40fb4903' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_portalvue_2f63a5cd from 'nuxt_plugin_portalvue_2f63a5cd' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_pluginclient_e471faa8 from 'nuxt_plugin_pluginclient_e471faa8' // Source: ./content/plugin.client.js (mode: 'client')
import nuxt_plugin_pluginserver_18ff1798 from 'nuxt_plugin_pluginserver_18ff1798' // Source: ./content/plugin.server.js (mode: 'server')
import nuxt_plugin_axios_80c87b74 from 'nuxt_plugin_axios_80c87b74' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginseo_423d5d49 from 'nuxt_plugin_pluginseo_423d5d49' // Source: ./nuxt-i18n/plugin.seo.js (mode: 'all')
import nuxt_plugin_pluginrouting_8dc41680 from 'nuxt_plugin_pluginrouting_8dc41680' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7e05a2a2 from 'nuxt_plugin_pluginmain_7e05a2a2' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_deviceplugin_7007b3d1 from 'nuxt_plugin_deviceplugin_7007b3d1' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_ggotrack_161eae74 from 'nuxt_plugin_ggotrack_161eae74' // Source: ../plugins/ggo-track.ts (mode: 'all')
import nuxt_plugin_ggoreferencecode_5b98c56c from 'nuxt_plugin_ggoreferencecode_5b98c56c' // Source: ../plugins/ggo-reference-code.ts (mode: 'all')
import nuxt_plugin_growthbook_4b08baee from 'nuxt_plugin_growthbook_4b08baee' // Source: ../plugins/growthbook.ts (mode: 'all')
import nuxt_plugin_preservedquery_510820ee from 'nuxt_plugin_preservedquery_510820ee' // Source: ../plugins/preserved-query.ts (mode: 'all')
import nuxt_plugin_user_54e5a70e from 'nuxt_plugin_user_54e5a70e' // Source: ../plugins/user.ts (mode: 'all')
import nuxt_plugin_googlemaps_0dbc66d4 from 'nuxt_plugin_googlemaps_0dbc66d4' // Source: ../plugins/google-maps.ts (mode: 'all')
import nuxt_plugin_jsonld_60b3bb59 from 'nuxt_plugin_jsonld_60b3bb59' // Source: ../plugins/jsonld.ts (mode: 'all')
import nuxt_plugin_ggoapilocale_72eb656e from 'nuxt_plugin_ggoapilocale_72eb656e' // Source: ../plugins/ggo-api-locale.ts (mode: 'all')
import nuxt_plugin_ggologger_17f23d6b from 'nuxt_plugin_ggologger_17f23d6b' // Source: ../plugins/ggo-logger.ts (mode: 'all')
import nuxt_plugin_webvitals_625e9739 from 'nuxt_plugin_webvitals_625e9739' // Source: ../plugins/web-vitals.ts (mode: 'all')
import nuxt_plugin_meta_3f226815 from 'nuxt_plugin_meta_3f226815' // Source: ./composition-api/meta.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"refreshOnceOnNavigation":true,"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"gogoout","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=3.0"},{"hid":"description","name":"description","content":""}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_54604f30 === 'function') {
    await nuxt_plugin_plugin_54604f30(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_52fc5932 === 'function') {
    await nuxt_plugin_plugin_52fc5932(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_01dc8496 === 'function') {
    await nuxt_plugin_recaptcha_01dc8496(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_674415ee === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_674415ee(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_40fb4903 === 'function') {
    await nuxt_plugin_vuescrollto_40fb4903(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_2f63a5cd === 'function') {
    await nuxt_plugin_portalvue_2f63a5cd(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginclient_e471faa8 === 'function') {
    await nuxt_plugin_pluginclient_e471faa8(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_18ff1798 === 'function') {
    await nuxt_plugin_pluginserver_18ff1798(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_80c87b74 === 'function') {
    await nuxt_plugin_axios_80c87b74(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseo_423d5d49 === 'function') {
    await nuxt_plugin_pluginseo_423d5d49(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_8dc41680 === 'function') {
    await nuxt_plugin_pluginrouting_8dc41680(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7e05a2a2 === 'function') {
    await nuxt_plugin_pluginmain_7e05a2a2(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_7007b3d1 === 'function') {
    await nuxt_plugin_deviceplugin_7007b3d1(app.context, inject)
  }

  if (typeof nuxt_plugin_ggotrack_161eae74 === 'function') {
    await nuxt_plugin_ggotrack_161eae74(app.context, inject)
  }

  if (typeof nuxt_plugin_ggoreferencecode_5b98c56c === 'function') {
    await nuxt_plugin_ggoreferencecode_5b98c56c(app.context, inject)
  }

  if (typeof nuxt_plugin_growthbook_4b08baee === 'function') {
    await nuxt_plugin_growthbook_4b08baee(app.context, inject)
  }

  if (typeof nuxt_plugin_preservedquery_510820ee === 'function') {
    await nuxt_plugin_preservedquery_510820ee(app.context, inject)
  }

  if (typeof nuxt_plugin_user_54e5a70e === 'function') {
    await nuxt_plugin_user_54e5a70e(app.context, inject)
  }

  if (typeof nuxt_plugin_googlemaps_0dbc66d4 === 'function') {
    await nuxt_plugin_googlemaps_0dbc66d4(app.context, inject)
  }

  if (typeof nuxt_plugin_jsonld_60b3bb59 === 'function') {
    await nuxt_plugin_jsonld_60b3bb59(app.context, inject)
  }

  if (typeof nuxt_plugin_ggoapilocale_72eb656e === 'function') {
    await nuxt_plugin_ggoapilocale_72eb656e(app.context, inject)
  }

  if (typeof nuxt_plugin_ggologger_17f23d6b === 'function') {
    await nuxt_plugin_ggologger_17f23d6b(app.context, inject)
  }

  if (typeof nuxt_plugin_webvitals_625e9739 === 'function') {
    await nuxt_plugin_webvitals_625e9739(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_3f226815 === 'function') {
    await nuxt_plugin_meta_3f226815(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
