declare global {
  interface Window {
    clarity?: any
  }
}

/**
 * Clarity 同意聲明，
 * 敲下去代表使用者同意 Clarity 使用 cookie
 */
export const clarityConsent = () => {
  window.clarity?.('consent')
}
