




















import { defineComponent, onMounted, ref, useContext, watch } from '@nuxtjs/composition-api'

import useDefaultMeta from '@/composables/use-default-meta'
import useI18n from '@/composables/use-i18n'
import useSearchBar from '@/composables/use-search-bar'

export default defineComponent({
  head: {},
  layout: 'standard',
  name: 'Index',
  setup () {
    const i18n = useI18n()

    useDefaultMeta({
      title: String(i18n.t('SEO.index.title')),
      description: String(i18n.t('SEO.index.metaDescription')),
    })

    const hertzReturnStore = ref<Object | null>(null)

    const { query } = useContext()
    const {
      location,
      locationReturn,
      dateHourRange,
      seats,
      queryObject,
      setValueFromQuery,
      setLocationFromStorage,
      setReturnLocationFromStorage,
      saveLocation,
      type,
      pushQuery,
    } = useSearchBar()

    onMounted(() => {
      // 從 localStorage 設定前一次的地點（如果有的話）
      setLocationFromStorage()
      setReturnLocationFromStorage()
      watch(query, setValueFromQuery, { immediate: true })
    })

    const search = () => {
      pushQuery(queryObject.value)
      saveLocation()
    }

    return {
      hertzReturnStore,
      type,
      location,
      locationReturn,
      dateHourRange,
      seats,
      search,
    }
  },
})
