import { useContext } from '@nuxtjs/composition-api'
import * as _ from 'lodash'
import { isArray } from 'typechecker'

export default function () {
  const { store } = useContext()

  /**
   *
   * @param key feature flag 的 key
   * @param value feature flag 的 value，可以是 Array 代表多種可能性
   * @returns 是否有命中 feature value
   */
  const match = (key: string, value: boolean | string | number | object) => {
    if (isArray(value)) {
      return _.some(value as Array<any>, value => _.isEqual(store?.state.growthbook.flags[key], value))
    }
    return _.isEqual(store?.state.growthbook.flags[key], value)
  }

  return {
    match,
  }
}
