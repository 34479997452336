import { render, staticRenderFns } from "./IndexSectionCarousel.vue?vue&type=template&id=ae6670ac&scoped=true&lang=pug&"
import script from "./IndexSectionCarousel.vue?vue&type=script&lang=ts&"
export * from "./IndexSectionCarousel.vue?vue&type=script&lang=ts&"
import style0 from "./IndexSectionCarousel.vue?vue&type=style&index=0&id=ae6670ac&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae6670ac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GSihImage: require('/app/components/GSihImage.vue').default,GCarousel: require('/app/components/GCarousel.vue').default,HintGoPointsWhatIs: require('/app/components/hint/HintGoPointsWhatIs.vue').default,IndexWrapperSection: require('/app/components/index/IndexWrapperSection.vue').default})
