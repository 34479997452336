























































import { computed, defineComponent } from '@vue/composition-api'
import useBreakpoint from '@/composables/use-breakpoint'
import { FeatureFlagKey } from '@/modules/growthbook/feature-flag'

export default defineComponent({
  setup () {
    const { match } = useBreakpoint()
    const isMobile = !match('lg')

    const imgSrc = computed(() =>
      isMobile
        ? 'https://cdn.gogoout.com/web/index/banner-m.jpg'
        : 'https://cdn.gogoout.com/web/index/banner.jpg'
    )
    // FIXME: 實驗 22 移除
    const imgSrc1 = computed(() =>
      isMobile
        ? 'https://cdn.gogoout.com/web/index/banner1-m.jpg'
        : 'https://cdn.gogoout.com/web/index/banner1.jpg'
    )
    const imgWidth = computed(() =>
      isMobile ? 800 : 1440
    )

    return {
      imgSrc,
      imgSrc1,
      imgWidth,
      FeatureFlagKey,
    }
  },
})
